<template>
    <div class="app">
        <AppHeader fixed>
            <SidebarToggler class="d-lg-none" display="md" mobile/>
            <b-link class="navbar-brand" to="#">
                <b>{{ $config.VUE_APP_NAME }}</b>
            </b-link>
            <SidebarToggler class="d-md-down-none" display="lg"/>
            <b-navbar-nav class="d-md-down-none">
                <b-nav-item class="px-3" :href="$config.VUE_APP_WEB_URL" target="_blank"><i class="icon-home"></i> {{ $t('menu.to_website') }}</b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item class="d-md-down-none" v-if="0">
                    <i class="icon-bell"></i>
                    <b-badge pill variant="danger">5</b-badge>
                </b-nav-item>
                <DefaultHeaderDropdownAccnt/>
            </b-navbar-nav>
            <AsideToggler class="d-none d-lg-block" v-if="0" />
            <!--<AsideToggler class="d-lg-none" mobile />-->
        </AppHeader>
        <div class="app-body">
            <AppSidebar fixed>
                <SidebarHeader/>
                <SidebarForm/>
                <SidebarNav :navItems="nav"></SidebarNav>
                <SidebarFooter/>
                <SidebarMinimizer/>
            </AppSidebar>
            <main class="main">
                <Breadcrumb :list="list" v-if="0"/>
                <div class="container-fluid py-4 d-flex h-100 flex-column">
                    <router-view :key="$route.fullPath"></router-view>
                </div>
            </main>
            <AppAside v-if="0" fixed>
                <!--aside-->
                <DefaultAside/>
            </AppAside>
        </div>
        <TheFooter>
            <!--footer-->
            <div class="col">
                <a href="#">{{ $config.VUE_APP_NAME }}</a>
                <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
            </div>
            <div class="col-auto" v-if="0">
                <button :class="['btn btn-sm btn-light mr-2', $i18n.locale === 'de' ? 'active' : '']" @click="setLanguage('de')" title="DE"><span
                        class="flag-icon flag-icon-de"></span></button>
                <button :class="['btn btn-sm btn-light', $i18n.locale === 'nl' ? 'active' : '']" @click="setLanguage('nl')" title="NL"><span
                        class="flag-icon flag-icon-nl"></span></button>
            </div>
        </TheFooter>
    </div>
</template>

<script>
    import nav from '@/_nav'
    import {
        Aside as AppAside,
        AsideToggler,
        Breadcrumb,
        Footer as TheFooter,
        Header as AppHeader,
        Sidebar as AppSidebar,
        SidebarFooter,
        SidebarForm,
        SidebarHeader,
        SidebarMinimizer,
        SidebarNav,
        SidebarToggler
    } from '@coreui/vue'
    import DefaultAside from './DefaultAside'
    import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'

    export default {
        name: 'DefaultContainer',
        components: {
            AsideToggler,
            AppHeader,
            AppSidebar,
            AppAside,
            TheFooter,
            Breadcrumb,
            DefaultAside,
            DefaultHeaderDropdownAccnt,
            SidebarForm,
            SidebarFooter,
            SidebarToggler,
            SidebarHeader,
            SidebarNav,
            SidebarMinimizer
        },
        mounted() {
            // Refresh menu every second.
            setInterval(() => {
                this.nav = this.getNav()
            }, 60000)
        },
        data() {
            return {
                nav: this.getNav()
            }
        },
        computed: {
            name() {
                return this.$route.name
            },
            list() {
                return this.$route.matched.filter((route) => route.name || route.meta.label)
            }
        },
        methods: {
            getNav() {
                return nav().items.filter((item) => {
                  if(this.$auth.user().is_admin == 1) return true;
                  for(let key in item.roles) {
                    if(item.roles[key] == this.$auth.user().is_admin)
                      return true;
                  }
                  return false;
                });
            },
            setLanguage(code) {
                this.$i18n.locale = code;
                localStorage.setItem('locale', code);

                // Update nav items because they are not reactively translated.
                this.nav = nav().items;
            }
        }
    }
</script>
