export default function() {
    return {
        items: [
            {
                name: window.Vue.$t('menu.dashboard'),
                url: '/dashboard',
                icon: 'icon-speedometer',
            },
            {
                divider: true,
            },
            {
                name: window.Vue.$t('menu.orders_on_hold'),
                // Filter for status ON_HOLD.
                url: '/orders?filters=' + JSON.stringify({'status': [1]}),
                attributes: {
                    class: 'text-secondary',
                    exact: true
                },
                icon: 'icon-book-open text-secondary',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.orders_open'),
                // Filter for status PENDING.
                url: '/orders?filters=' + JSON.stringify({'status': [2]}),
                attributes: {
                    class: 'text-warning',
                    exact: true
                },
                icon: 'icon-book-open text-warning',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.orders_now'),
                // Filter for shipping_date and statuses ON_HOLD+PENDING. 'Kritisch'
                url: (() => {
                    let date = new window.Vue.$moment();
                    let date_from = date.format('YYYY-MM-DD');
                    let date_to = date.add(1, 'days').addWorkingDays(1).format('YYYY-MM-DD');
                    return '/orders?filters=' + JSON.stringify({
                        'shipping_date_from': date_from,
                        'shipping_date_to': date_to,
                        'status': [1,2] });
                })(),
                attributes: {
                    class: 'text-danger',
                    exact: true
                },
                icon: 'icon-book-open text-danger',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.orders_active'),
                // Filter for status PROCESSING.
                url: '/orders?filters=' + JSON.stringify({'status': [3]}),
                attributes: {
                    class: 'text-success',
                    exact: true
                },
                icon: 'icon-book-open text-success',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.orders_closed'),
                // Filter for status COMPLETED.
                url: '/orders?filters=' + JSON.stringify({'status': [4]}),
                attributes: {
                    class: 'text-info',
                    exact: true
                },
                icon: 'icon-book-open text-info',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.orders'),
                url: '/orders?filters=' + JSON.stringify({'status': [1,2,3,4,5,6,7]}),
                attributes: {
                    exact: true
                },
                icon: 'icon-book-open',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.planning_today'),
                // Filter for working days and payment status PAID.
                url: (() => {
                    let date = new window.Vue.$moment().addWorkingDays(1).format('YYYY-MM-DD');
                    return '/orders?filters=' + JSON.stringify({'shipping_date': date, 'status': [3]})
                })(),
                attributes: {
                    exact: true
                },
                icon: 'icon-calendar',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.planning_tomorrow'),
                // Filter for working days and payment status PAID.
                url: (() => {
                    let date = new window.Vue.$moment().addWorkingDays(2).format('YYYY-MM-DD');
                    return '/orders?filters=' + JSON.stringify({'shipping_date': date, 'status': [3]})
                })(),
                attributes: {
                    exact: true
                },
                icon: 'icon-calendar',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.transport'),
                url: '/transport',
                icon: 'icon-cloud-upload',
                roles: [3]
            },
            {
                divider: true,
            },
            {
                name: window.Vue.$t('menu.products'),
                url: '/products',
                icon: 'icon-list',
                roles:[0,3]
            },
            {
                name: window.Vue.$t('menu.transporters'),
                url: '/transporters',
                icon: 'icon-list',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.zones'),
                url: '/zones',
                icon: 'icon-map',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.transporter_zones'),
                url: '/transporter-zones',
                icon: 'icon-map',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.payment_methods'),
                url: '/payment-methods',
                icon: 'icon-credit-card',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.pickup_locations'),
                url: '/pickup-locations',
                icon: 'icon-location-pin',
                roles: [3]
            },
            {
                name: window.Vue.$t('menu.pages'),
                url: '/pages',
                icon: 'icon-note',
                roles:[0,3]
            },
            {
                name: window.Vue.$t('menu.users'),
                url: '/users',
                icon: 'icon-user',
            },
            {
                name: window.Vue.$t('menu.administration'),
                url: '/administration',
                icon: 'icon-drawer',
                roles:[2]
            },
            {
                name: window.Vue.$t('menu.settings'),
                url: '/settings',
                icon: 'icon-settings',
                roles:[3]
            }
        ]
    }
}
