<template>
    <AppHeaderDropdown right no-caret>
        <template slot="header">
            <img
                    :src="image"
                    class="img-avatar"
                    alt=""/>
        </template>
        \
        <template slot="dropdown">
            <b-dropdown-item :to="{name: 'users.show', params: { id: userId} }"><i class="fa fa-user"/> {{ email }}</b-dropdown-item>
            <b-dropdown-item :to="{name: 'settings.index'}"><i class="fa fa-wrench"/> Settings</b-dropdown-item>
            <b-dropdown-item @click="logout"><i class="fa fa-lock"/> Logout</b-dropdown-item>
        </template>
    </AppHeaderDropdown>
</template>

<script>
    import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'

    export default {
        name: 'DefaultHeaderDropdownAccnt',
        components: {
            AppHeaderDropdown
        },
        created() {
            if(this.$auth.user()) {
                this.email = this.$auth.user().email;
                this.image = this.$auth.user().gravatar;
                this.userId = this.$auth.user().id;
            }
        },
        data() {
            return {
                userId: undefined,
                itemsCount: 42,
                email: undefined,
                image: undefined
            }
        },
        methods: {
            logout() {
                this.$auth.logout().then(() => {
                    this.$router.push({name: 'login'});
                    this.$toastr.s('U bent uitgelogd.');
                });
            }
        }
    }
</script>
